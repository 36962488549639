<template>
  <div>
    <Header></Header>
    <div class="lone">
      <div class="banner">
        <div class="main_left">
          <div
            class="nav"
            v-for="(item, index) in navarr[0][num]"
            :key="index"
            @click="router(index)"
          >
            <div class="flex_row">
              <div>{{ item }}</div>
              <div>></div>
            </div>
          </div>
        </div>
        <div class="main_right">
          <div class="matingbox">
            <div class="img_header">
              <img :src="encnimg[num].imgurl" alt="" />
            </div>
            <div class="fs30">{{amenities[num].title}}</div>
            <div class="mating_img">
              <img src="../img/矢量智能对象拷贝4.png" alt="" />
            </div>
            <div class="mat">
              <div class="row">
                <div class="dis">
                  <img src="../img/医院1.png" alt="" />
                  <div>{{amenities[num].icondata1}}</div>
                </div>
                <div class="dis">
                  <img src="../img/医院1.png" alt="" />
                  <div>{{amenities[num].icondata2}}</div>
                </div>
              </div>
              <div class="row">
                <div class="dis">
                  <img src="../img/医院1.png" alt="" />
                  <div>{{amenities[num].icondata3}}</div>
                </div>
                <div class="dis">
                  <img src="../img/医院1.png" alt="" />
                  <div>{{amenities[num].icondata4}}</div>
                </div>
              </div>
            </div>
            <div class="mat">
              <div class="row">
                <div class="dis">
                  <img src="../img/学校1.png" alt="" />
                  <div>{{amenities[num].icondata5}}</div>
                </div>
                <div class="dis">
                  <img src="../img/学校1.png" alt="" />
                  <div>{{amenities[num].icondata6}}</div>
                </div>
              </div>
              <div class="row">
                <div class="dis">
                  <img src="../img/学校1.png" alt="" />
                  <div>{{amenities[num].icondata7}}</div>
                </div>
                <div class="dis">
                  <img src="../img/学校1.png" alt="" />
                  <div>{{amenities[num].icondata8}}</div>
                </div>
              </div>
              <div class="row">
                <div class="dis">
                  <img src="../img/学校1.png" alt="" />
                  <div>{{amenities[num].icondata9}}</div>
                </div>
              </div>
            </div>
            <div class="mat">
              <div class="row">
                <div class="dis">
                  <img src="../img/高尔夫2.png" alt="" />
                  <div>{{amenities[num].icondata10}}</div>
                </div>
                <div class="dis">
                  <img src="../img/射击馆.png" alt="" />
                  <div>{{amenities[num].icondata11}}</div>
                </div>
              </div>
              <div class="row">
                <div class="dis">
                  <img src="../img/拳击馆.png" alt="" />
                  <div>{{amenities[num].icondata12}}</div>
                </div>
                <div class="dis">
                  <img src="../img/电影院2.png" alt="" />
                  <div>{{amenities[num].icondata13}}</div>
                </div>
              </div>
              <div class="row">
                <div class="dis">
                  <img src="../img/SPA.png" alt="" />
                  <div>{{amenities[num].icondata25}}</div>
                </div>
                <div class="dis">
                  <img src="../img/查汶海滩.png" alt="" />
                  <div>{{amenities[num].icondata14}}</div>
                </div>
              </div>
              <div class="row">
                <div class="dis">
                  <img src="../img/电影院2.png" alt="" />
                  <div>{{amenities[num].icondata15}}</div>
                </div>
                <div class="dis">
                  <img src="../img/高尔夫2.png" alt="" />
                  <div>{{amenities[num].icondata16}}</div>
                </div>
              </div>
            </div>
            <div class="mat none">
              <div class="row">
                <div class="dis">
                  <img src="../img/MAKRO.png" alt="" />
                  <div>{{amenities[num].icondata17}}</div>
                </div>
                <div class="dis">
                  <img src="../img/starbucks.png" alt="" />
                  <div>{{amenities[num].icondata18}}</div>
                </div>
              </div>
              <div class="row">
                <div class="dis">
                  <img src="../img/TESCO.png" alt="" />
                  <div>{{amenities[num].icondata19}}</div>
                </div>
                <div class="dis">
                  <img src="../img/big-c.png" alt="" />
                  <div>{{amenities[num].icondata20}}</div>
                </div>
              </div>
              <div class="row">
                <div class="dis">
                  <img src="../img/机场.png" alt="" />
                  <div>{{amenities[num].icondata21}}</div>
                </div>
                <div class="dis">
                  <img src="../img/7-11.png" alt="" />
                  <div>{{amenities[num].icondata22}}</div>
                </div>
              </div>
              <div class="row">
                <div class="dis">
                  <img src="../img/familymart.png" alt="" />
                  <div>{{amenities[num].icondata23}}</div>
                </div>
                <div class="dis">
                  <img src="../img/centralfestival.png" alt="" />
                  <div>{{amenities[num].icondata24}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/Herder.vue";
import Footer from "../components/Footer.vue";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["num"]),
  },
  components: { Header, Footer },
  data() {
    return {
      encnimg:[
        {
          imgurl:require('../img/headerimg.png')
        },
        {
          imgurl:require('../img/headerenimg.png')
        }
      ],
      navarr: [
        {
          0: ["醇熟配套"],

          1: ["Prime Location"],
        },
      ],
      amenities:[
        { 
          title:'醇熟配套',
          icondata1:'Bandon国际医院',
          icondata2:'泰国国际医院',
          icondata3:'曼谷国际医院',
          icondata4:'苏梅国际医院',
          icondata5:'苏梅国际学校',
          icondata6:'苏梅旅游学校',
          icondata7:'拉迈国际学校',
          icondata8:'Oonrak国际学校',
          icondata9:'英国Panyadee国际学校',
          icondata10:'高尔夫球场',
          icondata11:'射击馆',
          icondata12:'拳击馆',
          icondata13:'Tesco电影院',
          icondata14:"查汶海滩",
          icondata15:"Central Festival电影院",
          icondata16:"Santiburi高尔夫球场",
          icondata17:"MAKRO超市",
          icondata18:"Starbucks咖啡",
          icondata19:"Lotus超市",
          icondata20:"BIG-C超市",
          icondata21:"苏梅国际机场",
          icondata22:"7-11便利店",
          icondata23:"Family Mart便利店",
          icondata24:"Central Festival购物中心",
          icondata25:"Let's Relax SPA",

        },
        {     
          title:'Prime Location',
          icondata1:'Bandon International Hospital',
          icondata2:'Thailand International Hospital',
          icondata3:'Bangkok Hospital',
          icondata4:'Samui International Hospital',
          icondata5:'International School Samui',
          icondata6:'Samui Tourism School',
          icondata7:'Lamai International School',
          icondata8:'Oonrak International School',
          icondata9:'Panyadee British International School',
          icondata10:'Golf Club',
          icondata11:'Shooting Gallery',
          icondata12:'Muay Thai',
          icondata13:'Tesco Cinema',
          icondata14:"Chaweng Beach",
          icondata15:"Central Festival Cinema",
          icondata16:"Santiburi Golf Club",
          icondata17:"MAKRO",
          icondata18:"Starbucks",
          icondata19:"Lotus's",
          icondata20:"BIG-C",
          icondata21:"Samui International Airport",
          icondata22:"Seven-Eleven",
          icondata23:"Family Mart",
          icondata24:"Central Festival",
          icondata25:"Let's Relax SPA",
        }
      ]
    };
  },
};
</script>

<style lang="less" scoped>
.none{
  border: none !important;
}
.mat {
  margin: 0.3rem auto;
  border-bottom: 0.02rem solid #dad5ce;
}
.row {
  display: flex;
  width: 8rem;

  margin: 0.3rem auto;
}
.dis {
  width: 50%;
  display: flex;
  img {
    height: 0.30rem;
  }
  div {
    font-size: 0.17rem;
    line-height: 0.30rem;
    margin-left: 0.1rem;
  }
}
.matingbox {
  width: 8rem;
  height: 100%;
  margin: 0 auto;
}
.mating_img {
  img {
    width: 100%;
  }
}
.img_header {
  height: 0.8rem;
  width: 4.26rem;
  margin: 0 auto;
  img{
    width: 100%;
  }
}
.fs30 {
  margin: 0.8rem auto;
  font-size: 0.3rem;
  text-align: center;
}
.lone {
  width: 100%;
  background-color: #f3f3f4;
  overflow: hidden;
}
.flex_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 0.65rem;
  padding-left: 0.5rem;
  padding-right: 0.2rem;
}
.nav {
  width: 2.7rem;
  height: 0.65rem;
  background: white;
  margin-bottom: 0.05rem;
}
.nav:hover {
  background-color: #a08e75;
  color: white;
}
.banner {
  width: 12.6rem;

  margin: 1rem auto;
  display: flex;
}
.main_left {
  width: 3.47rem;
  height: 100%;
  border-right: 0.02rem solid #dad5ce;
}
.main_right {
  flex: 1;
}
</style>